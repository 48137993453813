
import {
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  useAttrs,
  unref,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiBase, ApiSalesOrders } from "@/core/api";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import {
  commonBackToList,
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import {
  SalesOrderOverview,
  salesOrderOverviewFormData,
  salesOrderProfillingFormData,
} from "@/core/directive/interface/salesOrder";

export default defineComponent({
  name: "mb-order-routing-routing-overview-overview",
  components: {},
  setup(props, context) {
    const { t } = useI18n();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButtonRef = ref<Array<HTMLElement | null>>([]);

    const submitButton = (el) => {
      submitButtonRef.value.push(el);
    };

    const setSubmitAttribute = (flag = true) => {
      if (!submitButtonRef.value) return;
      submitButtonRef.value.map((el) => {
        if (flag) {
          el?.setAttribute("data-kt-indicator", "on");
        } else {
          el?.removeAttribute("data-kt-indicator");
        }
      });
    };

    const route = useRoute();
    const router = useRouter();
    const attrs = useAttrs(); // 接收父路由参数
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref(Object.assign({}, salesOrderProfillingFormData));
    const salesOrderOverviewData = ref(
      Object.assign({}, salesOrderOverviewFormData)
    );

    const rules = ref({});

    let defaultFormInfo = unref(formData.value);

    const options = ref({});

    watch(
      () => attrs.loading,
      (newValue) => {
        const data = attrs.detailData as SalesOrderOverview;
        if (data) {
          salesOrderOverviewData.value = data;
        }
      }
    );

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "sales_order_shipping_mode",
          "sales_order_priority_level",
          "sales_order_distribution_mode",
          "required_documents_tagging",
          "exception_risk_level",
          "definite_inventory_source",
          "fulfillment_packaging_service",
          "sales_specification_mb_segmentation",
          "sales_specification_credit_risk_control",
          "sales_order_settlement_type",
          "sales_order_setttlement_mode",
          "sales_order_priority_handling",
          "sales_profilling_mb_order_type",
          "customer_clearance_mode",
        ],
      });
      if (data.code == 0) {
        // console.log()
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate(async (valid: boolean) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          setSubmitAttribute();
          const { data } = await ApiSalesOrders.updateSalesOrderSpecification({
            order_id: route.params.id,
            distribution: formData.value.distribution,
            priority_management: formData.value.priority_management,
            quotation_information: formData.value.quotation_information,
            pim: formData.value.pim,
            fulfillment: formData.value.fulfillment,
            settlement_bc_payment: formData.value.settlement_bc_payment,
            settlement_fraud_controlling:
              formData.value.settlement_fraud_controlling,
            settlement_tax_refund: formData.value.settlement_tax_refund,
          });
          loading.value = false;
          setSubmitAttribute(false);
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              refreshInfo();
            });
          } else {
            showServerErrorMsg(data);
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const backToList = () => {
      commonBackToList(router, "/sales-order/order-overview");
    };

    const refreshInfo = () => {
      loading.value = true;
      Promise.all([])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getFormInfo = () => {
      loading.value = true;
      Promise.all([])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onBeforeMount(() => {
      getFormInfo();
    });

    onMounted(() => {
      setModuleBCN(t, route, router);
      if (!attrs.loading) {
        const data = attrs.detailData as SalesOrderOverview;
        if (data) {
          salesOrderOverviewData.value = data;
        }
      }
    });

    return {
      attrs,
      t,
      formatDate,
      formatDateTime,
      loading,
      formData,
      salesOrderOverviewData,
      defaultFormInfo,
      options,
      rules,
      formRef,
      submitButton,
      backToList,
      submit,
    };
  },
});
